<template>
  <div class="container">
    <BaseTitle v-if="title">{{ title }}</BaseTitle>

    <InfiniteLoading
      v-slot="{ allData: offers }"
      ref="infiniteLoadingWrapper"
      :data="data?.offers"
      :has-more-to-load="hasMoreToLoad"
      :pixel-offset="100"
      class="grid"
      @load-more="loadMore"
    >
      <OfferItem
        v-for="offer in offers"
        :key="offer.id"
        :offer="offer"
        :hotspot-id="offer.hotspot.id"
        @click="emit('offerClicked', offer)"
      />

      <!-- No results messages -->
      <div v-if="!offers.length" class="full-width">
        <!-- No results on search -->
        <BaseText v-if="input?.search" class="bold centered-block">
          {{ $t("search.no-results") }}
        </BaseText>

        <!-- No results -->
        <BaseText v-else>
          {{ $t("offers.no-offers") }}
        </BaseText>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script setup lang="ts">
import type { ComponentExposed } from "vue-component-type-helpers";
import InfiniteLoading from "~/components/molecules/InfiniteLoading.vue";
import {
  type OffersInput,
  type PaginationInput,
  useOffersQuery,
  type OfferFragment,
} from "~/graphql/generated";

const props = defineProps<{
  title?: string;
  input?: OffersInput;
  pagination: PaginationInput;
  infiniteLoading?: boolean;
}>();

const emit = defineEmits(["offerClicked"]);

// Get the users location (if set)
const location = useLocationState();

// Mark this as reactive, so the query will re-run when the value changes
const pagination = toReactive(props.pagination);
const variables = computed(() => {
  return {
    offersInput: {
      ...props.input,
      ...(location.value &&
        !props.input?.citySlug && {
          point: {
            latitude: location.value.lat,
            longitude: location.value.long,
          },
        }),
    },
    pagination,
  };
});

const { data } = await useOffersQuery({
  variables,
});

const loadMore = () => {
  if (!props.infiniteLoading || !data?.value?.offers) return;
  pagination.offset += data?.value?.offers.length;
};

const hasMoreToLoad = computed(() => {
  if (!data?.value?.offers) return false;
  return data?.value?.offers?.length >= pagination.limit;
});

// Watch the input and reset the infinite loading
const infiniteLoadingWrapper = ref<ComponentExposed<
  typeof InfiniteLoading<OfferFragment>
> | null>(null);

watch(
  () => props.input,
  (newValue, oldValue) => {
    // Prevent the infinite loading from resetting when the input is the same
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;

    infiniteLoadingWrapper.value?.resetData();
    pagination.offset = 0;
  }
);
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

  @include for-small-screen {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  }

  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }

  .full-width {
    grid-column: 1 / -1;
  }
}

.two-columns .grid {
  grid-template-columns: 1fr 1fr;
}

.small-tiles .grid {
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
